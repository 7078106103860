<template>
  <div class="home">
    <CustomHeader></CustomHeader>
    <!-- Banner -->
    <div class="banner">
      <div class="banner-item">
        <div class="slogan core">
          <p class="p2 top">艾航科技</p>
          <div class="line"></div>
          <p class="p2 b-text">服务为体·科技为用</p>
        </div>
      </div>
    </div>
    <!-- 平台展示 -->
    <div class="bg-f">
      <div class="main">
        <h1 class="h1" data-aos="fade-down">无人机全自动化智能巡检作业平台</h1>
        <Title title="Automatic Patrol Inspection"></Title>
        <div class="img-wrap">
          <img class="mac" data-aos="fade-right" data-aos-delay="200" src="../../assets/img/home/mac.png" />
          <img class="pc" data-aos="fade-down" data-aos-delay="0" src="../../assets/img/home/pc.png" />
          <img class="control" data-aos="fade-left" data-aos-delay="200" src="../../assets/img/home/control.png" />
        </div>
      </div>
    </div>
    <!-- 平台优势 -->
    <div class="bg-w">
      <div class="app">
        <h1 class="h1" data-aos="fade-down">平台优势</h1>
        <Title title="Platform Advantages"></Title>
        <div class="tab-wrap">
          <div class="tab core">
            <div ref="one" @mouseover="nav($event, index)" v-for="(item, index) in tab" :key="index" :class="isActive == index ? 'active' : ''">
              <div>
                <div>
                  <img v-show="isActive == index" data-aos="flip-left" data-aos-delay="200" class="app-icon" :src="item.url" />
                  <img v-show="isActive != index" data-aos="flip-left" data-aos-delay="200" class="app-icon" :src="item.Hurl" />
                </div>
                <div data-aos="flip-left" data-aos-delay="200">{{ item.name }}</div>
              </div>
            </div>
            <div data-aos="flip-left" ref="bar" class="tab-bar"></div>
          </div>
          <transition mode="out-in" name="el-fade-in" appear>
            <div v-if="isActive == 0" class="inner core" key="213213">
              <div class="flex">
                <div class="inner-tab">
                  <div class="p1">
                    <span>自动飞行</span>
                    <span class="bg"></span>
                  </div>
                  <div class="p2">无人机全流程全自动化巡检作业</div>
                  <div class="p3">内嵌4G/5G模块，配合RTK厘米级定位技术，支持平台异地远程操控，无需专业的无人机飞行控制人员，无需学习培养无人机巡检作业经验，系统自主开展巡检作业任务，解放人力，灵活作业。</div>
                </div>
                <div>
                  <img class="tab-icon" src="../../assets/img/home/fly-tab.png" />
                </div>
              </div>
            </div>
            <div v-else-if="isActive == 1" class="inner core" key="active1">
              <div class="flex">
                <div class="inner-tab">
                  <div class="p1">
                    <span>航线规划</span>
                    <span class="bg"></span>
                  </div>
                  <div class="p2">航线自动规划，任务自动生成</div>
                  <div class="p3">通过激光雷达、倾斜摄影等技术测绘任务线路的GIS地理信息，自动规划航线，生成巡检任务；并支持shp、kml等多种格式的航线数据一键导入，支持WGS84、CGCS2000坐标系。</div>
                </div>
                <div>
                  <img class="tab-icon" src="../../assets/img/home/air-tab.png" />
                </div>
              </div>
            </div>
            <div v-else-if="isActive == 2" class="inner core" key="active2">
              <div class="flex">
                <div class="inner-tab">
                  <div class="p1">
                    <span>数据采集</span>
                    <span class="bg"></span>
                  </div>
                  <div class="p2">自动化数据采集，信息化采集成果</div>
                  <div class="p3">通过前端智能目标识别、智能目标跟踪、实时图像处理等行业前沿技术，对无人机巡检目标进行实时检测识别与跟踪，解放人力，实现无人机自主自动化数据采集，并信息化保存整理采集成果。</div>
                </div>
                <div>
                  <img class="tab-icon" src="../../assets/img/home/gather-tab.png" />
                </div>
              </div>
            </div>
            <div v-else-if="isActive == 3" class="inner core" key="active3">
              <div class="flex">
                <div class="inner-tab">
                  <div class="p1">
                    <span>算法分析</span>
                    <span class="bg"></span>
                  </div>
                  <div class="p2">多行业定制化算法分析</div>
                  <div class="p3">通过机载计算机的应用，使AI算法的运算控制突破时间局限性，在巡检作业过程中实时进行分析并输出成果；不局限于单一方向，针对不同行业需求进行定制化开发。</div>
                </div>
                <div>
                  <img class="tab-icon" src="../../assets/img/home/ai-tab.png" />
                </div>
              </div>
            </div>
            <div v-else-if="isActive == 4" class="inner core" key="active4">
              <div class="flex">
                <div class="inner-tab">
                  <div class="p1">
                    <span>数据安全</span>
                    <span class="bg"></span>
                  </div>
                  <div class="p2">让数据安全更简单</div>
                  <div class="p3">
                    从核心数据、数据交互、应用系统、移动办公、移动介质等多维度全面保障数据安全，防止敏感数据泄露，通过安全控制实现服务器数据下载强制加密，本地数据上传解密，同时提供准入控制、负载均衡等多层防护。
                  </div>
                </div>
                <div>
                  <img class="tab-icon" src="../../assets/img/home/data-tab.png" />
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <!-- 平台大图 -->
    <div class="bg-f">
      <div class="home_inner">
        <div class="core pt-3">
          <h1 class="h1 bg-title" data-aos="fade-down">艾航无人机巡检监控系统</h1>
          <Title title="Control Platform" activeColor="#fff" textColor="#fff"></Title>
        </div>
        <img class="opcity" src="@/assets/img/home/home-inner.png" />
        <img class="unopcity" style="padding: 0 16px; padding-bottom: 30px" src="@/assets/img/home/home-inner.png" />
      </div>
    </div>
    <!-- 图片 -->
    <div>
      <div class="section">
        <img class="picture" src="https://cdn.aihangtec.com/index/image/home/p1.png" />
        <div class="drawer" data-aos="fade-left" data-aos-delay="500">
          <div class="drawer-inner">
            <p class="title">智慧电力应用</p>
            <div class="inner">
              <p data-aos="fade-up" data-aos-offset="-300" @click.stop="plan('electric#comprehensive')">-无人机综合应用解决方案</p>
              <p data-aos="fade-up" data-aos-offset="-300" data-aos-delay="100" @click.stop="plan('electric#transport')">-输电巡检无人机应用</p>
              <p data-aos="fade-up" data-aos-offset="-300" data-aos-delay="200" @click.stop="plan('electric#match')">-配电巡检无人机应用</p>
              <p data-aos="fade-up" data-aos-offset="-300" data-aos-delay="300" @click.stop="plan('electric#transformer')">-变电站巡检无人机应用</p>
            </div>
            <div class="btn">
              <div id="draw-border">
                <button class="draw-btn opcity"><img src="../../assets/img/home/path.png" /></button>
                <img class="media-icon unopcity" src="../../assets/img/home/path.png" />
                <div class="demoName" @click="plan('electric')">查看解决方案</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <img class="picture" src="https://cdn.aihangtec.com/index/image/home/p2.png" />
        <div class="drawer" style="left: 0" data-aos="fade-right" data-aos-duration="5000" data-aos-delay="300">
          <div class="drawer-inner left">
            <p class="title">智慧城市应用</p>
            <div class="inner">
              <p data-aos="fade-up" data-aos-offset="-300" @click.stop="plan('city#environment')">-环境治理</p>
              <p data-aos="fade-up" data-aos-offset="-300" data-aos-delay="100" @click.stop="plan('city#gongan')">-智慧公安</p>
              <p data-aos="fade-up" data-aos-offset="-300" data-aos-delay="200" @click.stop="plan('city#firecontrol')">-消防应急</p>
              <p data-aos="fade-up" data-aos-offset="-300" data-aos-delay="300" @click.stop="plan('city#traffic')">-交通管理</p>
              <p data-aos="fade-up" data-aos-offset="-300" data-aos-delay="400" @click.stop="plan('city#Infrastructure')">-智慧基建</p>
            </div>
            <div class="btn">
              <div id="draw-border">
                <button class="draw-btn opcity"><img src="../../assets/img/home/path.png" /></button>
                <img class="media-icon unopcity" src="../../assets/img/home/path.png" />
                <div class="demoName" @click="plan('city')">查看解决方案</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <img class="picture" src="https://cdn.aihangtec.com/index/image/home/p3.png" />
        <div class="drawer" data-aos="fade-left" data-aos-duration="5000" data-aos-delay="300">
          <div class="drawer-inner">
            <p class="title">无人机多行业应用</p>
            <div class="inner">
              <p data-aos="fade-up" data-aos-offset="-300" @click.stop="plan('uav#petroleum')">-石油化工</p>
              <p data-aos="fade-up" data-aos-offset="-300" data-aos-delay="100" aos-anchor-placement="top-bottom" @click.stop="plan('uav#communication')">-通信行业</p>
              <p data-aos="fade-up" data-aos-offset="-300" data-aos-delay="200" @click.stop="plan('uav#energy')">-新型能源</p>
              <p data-aos="fade-up" data-aos-offset="-300" data-aos-delay="300" @click.stop="plan('uav#agriculture')">-农业植保</p>
              <p data-aos="fade-up" data-aos-offset="-300" data-aos-delay="400" @click.stop="plan('uav#forestry')">-林业保护</p>
            </div>
            <div class="btn">
              <div id="draw-border">
                <button class="draw-btn opcity"><img src="../../assets/img/home/path.png" /></button>
                <img class="media-icon unopcity" src="../../assets/img/home/path.png" />
                <div class="demoName" @click="plan('uav')">查看解决方案</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 平台 -->
    <div class="bg-f">
      <div class="main">
        <h1 class="h1" data-aos="fade-down">客户认可</h1>
        <Title title="Customer Recognition"></Title>
        <CustomSwiper />
      </div>
    </div>
    <!-- Footer -->
    <CustomFooter></CustomFooter>
    <!-- Fixed -->
    <Fixed class="opcity"></Fixed>
  </div>
</template>

<script>
import AOS from "aos"
export default {
  data() {
    return {
      isActive: 0,
      swiper: {},
      tab: [
        {
          name: "自动飞行",
          url: require("@/assets/img/home/fly.png"),
          Hurl: require("@/assets/img/home/fly-h.png")
        },
        {
          name: "航线规划",
          url: require("@/assets/img/home/airway.png"),
          Hurl: require("@/assets/img/home/airway-h.png")
        },
        {
          name: "数据采集",
          url: require("@/assets/img/home/gather.png"),
          Hurl: require("@/assets/img/home/gather-h.png")
        },
        {
          name: "算法分析",
          url: require("@/assets/img/home/ai.png"),
          Hurl: require("@/assets/img/home/ai-h.png")
        },
        {
          name: "数据安全",
          url: require("@/assets/img/home/data.png"),
          Hurl: require("@/assets/img/home/data-h.png")
        }
      ]
    }
  },
  mounted() {
    AOS.init()
  },
  methods: {
    nav(e, i) {
      this.isActive = i
      const bar = e.target
      const ibar = document.getElementsByClassName("tab-bar")[0]
      this.$refs.bar.style.transform = `translateX(${bar.offsetLeft + (bar.clientWidth - ibar.offsetWidth) / 2}px)`
    },
    plan(url) {
      if (this.$route.fullPath == `/${url}`) return
      document.documentElement.scrollTop = 0
      this.$router.push(`/${url}`)
    },
    custom() {
      this.$router.push(`/custom`)
      document.documentElement.scrollTop = 0
    }
  }
}
</script>
<style scoped lang="scss">
$main: 1200px;
$swiper_height: 580px;
p {
  margin: 0;
}
.media-icon {
  width: 16px;
  height: 6px;
}

//opcity为pc端显示 而 移动端不显示
.opcity {
  @media screen and (max-width: 540px) {
    display: none;
  }
  @media screen and (min-width: 540px) {
    display: block;
  }
}
//unopcity为pc端不显示 而 移动端显示
.unopcity {
  @media screen and (max-width: 540px) {
    display: block;
  }
  @media screen and (min-width: 540px) {
    display: none;
  }
}
.swiper-container {
  width: 100%;
  padding: 20px;
  overflow: hidden;
  height: 560px;
  user-select: none;
}
.bot {
  height: 500px;
}
.main {
  margin: 0 auto;
  min-height: 300px;
  padding: 80px 0;
  overflow: hidden;
  @media screen and (max-width: 540px) {
    padding: 30px 0;
  }
  @media screen and (min-width: 540px) {
    padding: 80px 0;
  }
}

.banner {
  margin: 0;
  position: relative;
  top: 0;
}
.banner-item {
  height: 100vh;
  @media screen and (max-width: 540px) {
    height: 300px;
  }
  @media screen and (min-width: 540px) {
    height: 100vh;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  background-image: url(https://cdn.aihangtec.com/index/image/home/home-bg.png);
  background-size: 100% 100%;
  user-select: none;
  background-repeat: no-repeat;
  z-index: 9;
}
.slogan {
  position: relative;
  color: #fff;
  @media screen and (max-width: 540px) {
    position: relative;
    left: -75px;
  }
  .top {
    @media screen and (max-width: 540px) {
      font-size: 15px !important;
    }
    @media screen and (min-width: 540px) {
      font-size: 60px !important;
    }
  }
  .line {
    position: relative;
    @media screen and (max-width: 540px) {
      width: 120px;
      height: 1px;
    }
    @media screen and (min-width: 540px) {
      width: 330px;
      top: -15px;
      height: 2px;
    }
    background: #ffffff;
  }
  .p2 {
    position: relative;
    @media screen and (max-width: 540px) {
      font-size: 9px;
      line-height: 30px;
      top: 0;
    }
    @media screen and (min-width: 540px) {
      font-size: 32px;
      line-height: 54px;
    }
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 3px;
    text-align: left;
  }
  .b-text {
    @media screen and (max-width: 540px) {
      margin-top: 0px;
    }
    @media screen and (min-width: 540px) {
      margin-top: 25px;
    }
  }
  .p3 {
    height: 30px;
    padding: 10px 10px;
    background: var(--RootColor);
    border-radius: 28px;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 1px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    i {
      line-height: 30px;
      float: right;
      position: relative;
      right: 50px;
      transition: all 0.3s;
    }
  }
  .p3:hover {
    background: #3298fd;
  }
  .p3:hover i {
    right: 30px;
  }
}
.h5 {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.45);
  line-height: 21px;
  text-align: center;
}
.small {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
}
.img-wrap {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  img {
    position: relative;
  }
  .pc {
    @media screen and (max-width: 540px) {
      width: 180px;
      height: 140px;
    }
    @media screen and (min-width: 540px) {
      width: 450px;
      height: 380px;
      left: -75px;
    }
  }
  .mac {
    @media screen and (max-width: 540px) {
      width: 160px;
      height: 85px;
      top: 60px;
      left: 45px;
    }
    @media screen and (min-width: 540px) {
      width: 464px;
      height: 270px;
      left: -20px;
      top: 100px;
    }
    z-index: 99;
  }
  .control {
    @media screen and (max-width: 540px) {
      width: 140px;
      height: 120px;
      top: 20px;
      left: -50px;
    }
    @media screen and (min-width: 540px) {
      width: 312px;
      height: 345px;
      left: -100px;
      top: 25px;
    }
  }
}
.home_inner {
  width: 100%;
  position: relative;
  margin: 0px auto;
  background: url(../../assets/img/home/inner-bg.png) no-repeat;
  background-size: 100% 100%;
  user-select: none;
  .bg-title {
    color: rgba($color: #ffffff, $alpha: 0.85);
    position: relative;
    z-index: 99;
  }
  img {
    position: relative;
    @media screen and (max-width: 540px) {
      width: 100%;
    }
    @media screen and (min-width: 540px) {
      width: 64%;
    }
    margin: 0px auto;
    padding: 20px 0 60px;
    z-index: 99;
  }
}
.home_inner:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: inherit;
  filter: blur(3.2px) brightness(0.85);
}
.num-inner {
  height: 270px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: -10px;
  color: #fff;
  & > div {
    p:first-child {
      font-size: 50px;
      font-weight: 600;
    }
    p:last-child {
      font-size: 24px;
      font-weight: 500;
    }
  }
}
.app {
  margin: 0 auto;
  padding: 21px 0;
  @media screen and (max-width: 540px) {
    height: 400px;
  }
  @media screen and (min-width: 540px) {
    height: 600px;
  }
}
.tab-wrap {
  position: relative;
  height: 50px;
  padding: 20px;
  background: #f5faff;
  .tab {
    position: relative;
    display: flex;
    justify-content: space-between;
    color: #696969;
    letter-spacing: 1px;
    @media screen and (max-width: 540px) {
      font-size: 10px;
      text-align: center;
    }
    @media screen and (min-width: 540px) {
      font-size: 20px;
    }
    font-weight: 400;
    cursor: pointer;
    & > div {
      user-select: none;
      transition: all 0.3s;
    }
  }
  .tab-bar {
    position: absolute;
    @media screen and (max-width: 540px) {
      width: 48px;
      height: 1.5px;
    }
    @media screen and (min-width: 540px) {
      width: 84px;
      height: 3px;
    }
    bottom: -11px;
    background: var(--RootColor);
    border-radius: 10%;
    transition: all 0.3s;
  }
  .inner {
    @media screen and (max-width: 540px) {
      margin-top: 40px;
    }
    @media screen and (min-width: 540px) {
      margin-top: 60px;
    }
  }
  .active {
    color: var(--RootColor);
  }
}
.app-icon {
  @media screen and (max-width: 540px) {
    width: 31px;
    height: 30px;
    margin-bottom: 10px;
  }
  @media screen and (min-width: 540px) {
    width: 60px;
    height: 60px;
    margin-left: 10px;
  }
}
.tab-icon {
  @media screen and (max-width: 540px) {
    width: 104px;
    height: 100px;
    position: relative;
    top: 30px;
    margin-left: 20px;
  }
  @media screen and (min-width: 540px) {
    width: 260px;
    height: 250px;
  }

  animation: bounce-down 3s linear infinite;
  -webkit-animation: bounce-down 3s linear infinite;
}

.inner-tab {
  @media screen and (max-width: 540px) {
    padding-top: 15px;
  }
  @media screen and (min-width: 540px) {
    padding-top: 25px;
  }
  .p1 {
    position: relative;
    height: 30px;
    font-weight: 500;
    color: var(--RootColor);
    letter-spacing: 2px;
    @media screen and (max-width: 540px) {
      font-size: 16px;
      line-height: 16px;
    }
    @media screen and (min-width: 540px) {
      font-size: 30px;
      line-height: 45px;
      margin-bottom: 25px;
    }
    .bg {
      display: inline-block;
      position: absolute;
      left: 0;
      @media screen and (max-width: 540px) {
        top: 26px;
        width: 100px;
        height: 2.5px;
      }
      @media screen and (min-width: 540px) {
        top: 45px;
        width: 129px;
        height: 4px;
      }
      background: linear-gradient(90deg, #76a4ff 0%, rgba(10, 132, 255, 0) 100%);
    }
  }
  .p2 {
    @media screen and (max-width: 540px) {
      font-size: 10px;
    }
    @media screen and (min-width: 540px) {
      font-size: 18px;
    }
    height: 18px;
    font-weight: 500;
    color: #1e1e1e;
    line-height: 27px;
    letter-spacing: 1px;
    margin-bottom: 16px;
  }
  .p3 {
    @media screen and (max-width: 540px) {
      font-size: 10px;
      line-height: 16px;
    }
    @media screen and (min-width: 540px) {
      width: 595px;
      font-size: 16px;
      line-height: 30px;
    }
    font-weight: 400;
    color: rgba(39, 32, 104, 0.5);
    letter-spacing: 1px;
  }
}
.section {
  position: relative;
  overflow: hidden;
  .picture {
    width: 100%;
    height: 100vh;
    @media screen and (max-width: 540px) {
      height: 270px;
    }
    @media screen and (min-width: 540px) {
      height: 100vh;
    }
  }
  .drawer {
    position: absolute;
    width: 50%;
    @media screen and (max-width: 540px) {
      height: 270px;
    }
    @media screen and (min-width: 540px) {
      height: 100vh;
    }
    right: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.62);
    backdrop-filter: blur(10px);
    .left {
      position: absolute !important;
      @media screen and (max-width: 540px) {
        left: 10% !important;
        width: 100% !important;
      }
      @media screen and (min-width: 540px) {
        right: 100px !important;
      }
    }
    .drawer-inner {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      @media screen and (max-width: 540px) {
        margin-left: 20px;
      }
      @media screen and (min-width: 540px) {
        margin-left: 100px;
      }
      .title {
        height: 30px;
        @media screen and (max-width: 540px) {
          font-size: 12px;
        }
        @media screen and (min-width: 540px) {
          font-size: 30px;
        }
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 45px;
        letter-spacing: 3px;
        &::before {
          content: "|";
          position: relative;
          @media screen and (max-width: 540px) {
            font-size: 13px;
          }
          @media screen and (min-width: 540px) {
            top: -3px;
            font-size: 24px;
          }
          right: 5px;
          width: 2px;
          height: 24px;
          border-radius: 1px;
        }
      }
      .inner {
        font-size: 24px;
        @media screen and (max-width: 540px) {
          font-size: 10px;
          margin-top: 20px;
        }
        @media screen and (min-width: 540px) {
          font-size: 24px;
          line-height: 42px;
          margin-top: 60px;
        }
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        opacity: 0.5;
        cursor: pointer;
        user-select: none;
      }
      .btn {
        display: flex;
        justify-content: flex-start;
        position: relative;
        left: 0;
        transition: all 0.3s;
        @media screen and (max-width: 540px) {
          font-size: 10px;
        }
        @media screen and (min-width: 540px) {
          margin-top: 100px;
          font-size: 16px;
        }
        font-weight: 500;
        color: var(--RootColor);
        line-height: 60px;
        cursor: pointer;
        .demoName {
          margin-left: 20px;
        }
      }
    }
  }
}

@keyframes bounce-down {
  25% {
    transform: translateY(-6px);
  }
  50%,
  100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(6px);
  }
}

@-webkit-keyframes bounce-down {
  25% {
    -webkit-transform: translateY(-6px);
  }
  50%,
  100% {
    -webkit-transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(6px);
  }
}
</style>
<style>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
